// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;

// Plus imports for other components in your app.

@include mat.all-component-typographies;
@include mat.elevation-classes;
@include mat.app-background;

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$artemis-portal-primary: mat.m2-define-palette(mat.$m2-gray-palette, 900, 900, 900);
$artemis-portal-accent: mat.m2-define-palette(mat.$m2-light-blue-palette, 200, 100, 400);
$bsi-typography: mat.m2-define-typography-config(
  $font-family: "Noto Sans, sans-serif",
);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$artemis-portal-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $artemis-portal-primary,
      accent: $artemis-portal-accent,
    ),
    typography: $bsi-typography,
    density: -2,
  )
);

@include mat.all-component-themes($artemis-portal-theme);


/* You can add global styles to this file, and also import other style files */

// Very very important for layout, DO NOT REMOVE!
html,
body {
  width: 100%;
  height: 100%;
  min-width: 350px;

  --mdc-dialog-container-shape: 16px;
  --mdc-filled-button-container-shape: 10px;
  --mat-autocomplete-container-shape: 10px;
  --mdc-filled-button-label-text-weight: 600;
  --mat-snack-bar-button-color: #FBFBFB;
  --mdc-filled-button-container-height: 36px;
}

// use flex box for layout
body {
  margin: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

app-root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: stretch;
}

.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48;
}

.cdk-overlay-pane .mat-mdc-menu-panel {
  .mat-mdc-menu-item .mdc-list-item__primary-text {
    opacity: 0.8;
    font-size: 14px;
  }

  background: #FBFBFB;
}

.logo {
  width: 50px;
  height: 50px;
  margin-right: 8px;
}

mat-toolbar button {
  margin-left: 8px;
}

mat-toolbar a {
  margin-left: 8px;
}

mat-toolbar input {
  margin-left: 8px;
  padding: 8px;
}

.mat-mdc-card.mat-elevation-z6 {
  @include mat.elevation(6);
}

.mat-mdc-card.mat-elevation-z8 {
  @include mat.elevation(8);
}

.mat-mdc-card.mat-elevation-z10 {
  @include mat.elevation(10);
}

.toast-info {
  @include mat.snack-bar-overrides((
    container-color: #85d6f0,
    supporting-text-color: #24262e,
    button-color: #24262e,
  ));
}

.toast-error {
  @include mat.snack-bar-overrides((
    container-color: #d3350b,
    supporting-text-color: white,
    button-color: white,
  ));
}

.dot-container {
  position: relative;
  height: 32px;
  width: 32px;
}

.dot-white {
  background-color: #FBFBFB;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  left: 10px;
}

.dot-white-ring {
  border: 3px solid #FBFBFB;
  border-radius: 24px;
  height: 16px;
  width: 16px;
  position: absolute;
  left: 5px;
  top: 5px;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0.0
}

@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0.0;
  }

  50% {
    opacity: 1.0;
  }

  100% {
    transform: scale(1.2, 1.2);
    opacity: 0.0;
  }
}
